<template>
  <div class="page" id="indexList">
    <el-row :gutter="10">
      <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold','leftHeighe']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div class="leftTable" v-show="showList">
          <el-table
              :data="catalogueList"
              ref="catalogueTable"
              size="small"
              :show-header=false
              height="calc(100vh - 156px)"
              highlight-current-row
              @current-change="directorySelection">
            <el-table-column prop="total" label="资源鉴定"></el-table-column>
          </el-table>
        </div>
      </el-col>
      <!--全部资源-->
      <el-col v-if="selectCatalogueList.value === ''" key="0" :span="showList ? 20 : 24"
              :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <el-form size="small" class="query-form searchForm" ref="inputForm" :model="inputForm"
                 @keyup.enter.native="getDataList(1,1)"
                 label-width="80px">
          <el-form-item label="关键字" prop="resourceName">
            <el-input v-model.trim="inputForm.resourceName"
                      placeholder="请输入资源名称/文件名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label="成文时间" prop="accidentTime" class="datTime">
            <el-date-picker
                v-model="inputForm.accidentTime"
                type="daterange"
                style="width: 100%;"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <span style="margin-left: 15px">
        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
      </span>
          <el-button class="f_r" type="primary" size="small"
                     v-if="pageType != 'general'"
                     v-show="hasPermissionButton(`dyyg:comprehensive:indexList:add`)"
                     icon='el-icon-plus' @click="addData(0)">新增
          </el-button>
        </el-form>
        <div class="bg-white">
          <div v-if="pageType != 'general'" class="text_right">
            <el-button icon="el-icon-open" v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchOpen`)"
                       size="small" @click="bulkOperation('',1)">
              批量公开
            </el-button>
            <el-button icon="el-icon-turn-off" v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchOff`)"
                       size="small" @click="bulkOperation('',2)">
              批量隐藏
            </el-button>
            <el-button icon="el-icon-delete" v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchDelete`)"
                       type="danger" size="small" @click="bulkOperation('',0)">
              批量删除
            </el-button>
          </div>
          <el-table
              :data="dataList"
              size="small"
              v-loading="loading"
              ref="multipleTable"
              :height="pageType != 'general' ? 'calc(100vh - 327px)' : 'calc(100vh - 295px)'"
              class="table"
              :row-key="'id'"
              :header-cell-style="{background:'#F9DFDF'}"
              @selection-change="selectionChangeHandle"
          >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="url" label="缩略图" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-image fit="contain" :src="scope.row.url" class="itemImg">
                  <el-image slot="placeholder" fit="cover" class="itemImg"
                            :src="require('@/assets/img/default.png')"></el-image>
                  <el-image slot="error" fit="cover" class="itemImg"
                            :src="require('@/assets/img/default.png')"></el-image>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="writtenDate" sortable min-width="100px" label="成文时间"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="fileCount" sortable min-width="100px" label="文件数量"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="fileSizeFormat" sortable min-width="100px" label="文件大小"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceDesc" label="资源描述" show-overflow-tooltip></el-table-column>
            <el-table-column v-if="pageType != 'general'" prop="openFlag" label="公开状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-switch
                    v-model="scope.row.openFlag"
                    @change="bulkOperation(scope.row,scope.row.openFlag == 0 ? 1 : 2)"
                    active-value="0"
                    inactive-value="1">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="createUserName" label="创建人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right" label="操作" width="180">
              <template slot-scope="scope">
                <el-button size="mini" type="text"
                           @click="resourceDetails(1, scope.row)"
                           v-show="hasPermissionButton(`dyyg:comprehensive:${pageType}:detail`)">
                  详情
                </el-button>
                <el-button size="mini" type="text"
                           v-if="pageType != 'general'"
                           @click="bulkOperation(scope.row,0)"
                           v-show="hasPermissionButton(`dyyg:comprehensive:indexList:delete`)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="text_center">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>

      <el-col v-else :span="showList ? 20 : 24" key="1"
              :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <el-form size="small" class="query-form" @submit.native.prevent inline @keyup.enter.native="getDataList(1,1)"
                 label-width="80px">
          <el-form-item label="关键字" prop="resourceName">
            <el-input v-model.trim="resourceName"
                      placeholder="请输入文件名称(限50字)"
                      maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
            <el-button size="small" @click="resettingFile()" icon='el-icon-refresh-right'>重置</el-button>
          </el-form-item>
          <el-form-item class="f_r">
            <el-button type="primary" size="small" v-if="pageType != 'general'" v-show="hasPermissionButton(`dyyg:comprehensive:indexList:add`)"
                       icon='el-icon-plus' @click="fileAddData(0)">新增
            </el-button>
          </el-form-item>
        </el-form>
        <div class="bg-white">
          <div class="text_right">
            <el-button v-if="pageType != 'general'" size="small" v-show="hasPermissionButton(`dyyg:comprehensive:indexList:move`)" icon='el-icon-rank'
                       @click="fileMove()">批量移动
            </el-button>
            <el-button v-if="pageType != 'general'" icon="el-icon-delete" v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchDelete`)"
                       type="danger" size="small" @click="fileBulkOperation('',0)">
              批量删除
            </el-button>
            <el-button icon="el-icon-download" size="small"
                       @click="downloadFile()"
                       v-if="pageType == 'general'"
                       v-show="hasPermissionButton(`dyyg:comprehensive:${pageType}:batchDownload`)">
              批量下载
            </el-button>
          </div>
          <el-table
              :data="dataList"
              size="small"
              v-loading="loading"
              ref="multipleTable"
              height="calc(100vh - 330px)"
              class="table"
              :row-key="'id'"
              :header-cell-style="{background:'#F9DFDF'}"
              @selection-change="selectionChangeHandle"
          >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column prop="resourceName" label="文件名" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="flex_l_c">
                  <el-image v-if="scope.row.folderFlag == 0" fit="contain" :src="require('@/assets/img/folder.png')"
                            class="itemImg"></el-image>
                  <el-image v-else fit="contain" :src="scope.row.url" class="itemImg">
                    <el-image slot="placeholder" fit="cover" class="itemImg"
                              :src="getPlaceholderImg(scope.row.type)"></el-image>
                    <el-image slot="error" fit="cover" class="itemImg"
                              :src="getPlaceholderImg(scope.row.type)"></el-image>
                  </el-image>
                  <div v-html="scope.row.fileName" style="padding-left: 5px"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" sortable label="修改时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="owningFolder" sortable label="所属文件夹" show-overflow-tooltip></el-table-column>
            <el-table-column prop="typeName" label="资源类型" show-overflow-tooltip></el-table-column>
            <el-table-column prop="fileSizeFormat" sortable label="大小" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createUserName" label="创建人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right" label="操作" width="180">
              <template slot-scope="scope">
                <el-button size="mini" type="text"
                           @click="fileDetail(1, scope.row)"
                           v-show="hasPermissionButton(`dyyg:comprehensive:${pageType}:detail`)">
                  详情
                </el-button>
                <el-button size="mini" type="text"
                           v-if="pageType != 'general'"
                           @click="fileDetail(2, scope.row)"
                           v-show="hasPermissionButton(`dyyg:comprehensive:indexList:edit`)">
                  修改
                </el-button>
                <el-button size="mini" type="text"
                           v-if="pageType != 'general'"
                           @click="fileMove(scope.row)"
                           v-show="hasPermissionButton(`dyyg:comprehensive:indexList:move`)">
                  移动
                </el-button>
                <el-button size="mini" type="text"
                           @click="fileBulkOperation(scope.row)"
                           v-if="pageType != 'general'"
                           v-show="hasPermissionButton(`dyyg:comprehensive:indexList:delete`)">
                  删除
                </el-button>
                <el-button size="mini" type="text"
                           @click="downloadFile(scope.row)"
                           v-if="pageType == 'general'"
                           v-show="hasPermissionButton(`dyyg:comprehensive:${pageType}:download`)">
                  下载
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="text_center">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <add-comprehensive ref="addComprehensive" @refreshList="getDataList(1,1)"></add-comprehensive>
    <detail-comprehensive ref="detailComprehensive" @refreshList="getDataList()"></detail-comprehensive>
    <file-add ref="fileAdd"></file-add>
    <file-move ref="fileMove"></file-move>
    <file-details ref="fileDetails"></file-details>
  </div>
</template>

<script>
import AddComprehensive from "@/views/modules/dyyg/comprehensive/addComprehensive.vue";
import FileAdd from "@/views/modules/dyyg/comprehensive/fileAdd.vue";
import FileMove from "@/views/modules/dyyg/comprehensive/fileMove.vue";
import DetailComprehensive from "@/views/modules/dyyg/comprehensive/detailComprehensive.vue";
import FileDetails from "@/views/modules/dyyg/comprehensive/fileDetails.vue";

export default {
  name: "indexList",
  components: {FileDetails, DetailComprehensive, FileMove, FileAdd, AddComprehensive},
  props:{
    pageType:{
      type:String,
      default:"indexList",
    },
  },
  data() {
    return {
      showList: true,
      selectCatalogueList: {},
      catalogueList: [
        {
          value: '',
          total: '全部资源',
        },
        {
          value: 0,
          total: '三维',
        },
        {
          value: 1,
          total: '图片',
        },
        {
          value: 2,
          total: '音频',
        },
        {
          value: 3,
          total: '视频',
        },
        {
          value: 4,
          total: '出版物',
        },
        {
          value: 5,
          total: '文献',
        },
        {
          value: 6,
          total: '文件',
        },
        {
          value: 7,
          total: '其它',
        },
      ],
      inputForm: {
        resourceName: '',
        accidentTime: [],
        startTime: '',
        endTime: '',
      },
      dataList: [],
      loading: false,
      dataListSelect: [],

      current: 1,
      size: 10,
      total: 0,

      resourceName: '',
    }
  },

  mounted() {
    this.$refs.catalogueTable.setCurrentRow(this.catalogueList[0])
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      if (this.inputForm.accidentTime && this.inputForm.accidentTime.length != 0) {
        this.inputForm.startTime = this.inputForm.accidentTime[0]
        this.inputForm.endTime = this.inputForm.accidentTime[1]
      } else {
        this.inputForm.startTime = ''
        this.inputForm.endTime = ''
        this.inputForm.accidentTime = []
      }
      let searchRecord = {
        ...this.inputForm,
        openFlag: this.pageType != 'general' ? '' : 0,
        current: this.current,
        size: this.size,
        type:this.selectCatalogueList.value,
      }
      let fechUrl = ''
      if (this.selectCatalogueList.value === '') { //是否 选择全部资源
        if (this.inputForm.resourceName == '' && this.inputForm.accidentTime.length == 0) {
          //列表没有搜索项时
          fechUrl = this.api.dyyg.comprehensiveResourcesList
        } else {
          //列表有搜索项时,去搜索结果页面
          this.$router.push({
            path: '/dyyg/comprehensive/searchResult',
            query: {inputForm: this.inputForm,pageType:this.pageType},
          })
        }
      } else {
        //左侧搜相对应的文件时
        fechUrl = this.api.dyyg.resourceSearchByType
        searchRecord = {
          resourceName:this.resourceName,
          openFlag: this.pageType != 'general' ? '' : 0,
          current: this.current,
          size: this.size,
          type:this.selectCatalogueList.value,
        }
      }
      this.$axios(fechUrl, searchRecord, 'post').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--;
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    //左边列表选择
    directorySelection(news) {
      this.selectCatalogueList = news
      this.total = 0
      this.resourceName = ''
      this.dataListSelect = []
      this.getDataList(1, 1)
    },

    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //资源新增
    addData(num) {
      this.$refs.addComprehensive.init()
    },

    //资源详情和修改
    resourceDetails(num,row){
      this.$refs.detailComprehensive.init(row.id,this.pageType)
    },

    //num 0批量删除 1批量公开 2批量隐藏
    bulkOperation(row, num) {
      let ids
      if (!row) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        if (num != 0) {
          if (row.openFlag == '0') {
            row.openFlag = '1'
          } else {
            row.openFlag = '0'
          }
        }
        ids = [row.id]
      }
      this.$confirm(`您是否确认${num == 0 ? '删除' : num == 1 ? '公开' : '隐藏'}该数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.updateResourcesByTypeAndIds, {
          ids,
          type: num,
        }, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(`${num == 0 ? '删除' : num == 1 ? '公开' : '隐藏'}成功`)
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //文件表格操作
    //文件搜索重置
    resettingFile(){
      this.resourceName = ''
      this.getDataList(1, 1)
    },

    //文件新增
    fileAddData(){
      this.$refs.fileAdd.init(this.selectCatalogueList.value)
    },

    //文件移动
    fileMove(row){
      let ids
      if (!row) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$refs.fileMove.init(ids)
    },

    //文件批量删除
    fileBulkOperation(row){
      let ids
      if (!row) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$confirm(`删除该数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.comprehensiveResourcesFileLinkRemoveByIds, ids, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(`删除成功`)
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //文件批量下载
    downloadFile(row){
      let list = row ? [row] : this.dataListSelect
      let ids
      if (list.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = list.map(item => {
        if (item) {
          return item.id
        }
      })
      this.$message.info('文件已开始下载，请勿重复操作')
      // this.$axios(this.api.collection.batchDownload, ids, 'post', 'blob').then((res) => {
      //   let blob = new Blob([res.data], {type: 'application/zip'})
      //   let filename = '事故资料';
      //   let link = document.createElement('a')
      //   link.download = decodeURI(filename)
      //   link.href = window.URL.createObjectURL(blob)
      //   link.click()
      // })
    },

    //文件 1详情 2修改
    fileDetail(num,row){
      this.$refs.fileDetails.init(row)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', 1);
    },

    // 获取占位图
    getPlaceholderImg(resourceType) {
      switch (resourceType) {
        case 0:
          return require('@/assets/img/collThree.png')
        case 1:
          return require('@/assets/img/collPhoto.png')
        case 2:
          return require('@/assets/img/collAudio.png')
        case 3:
          return require('@/assets/img/collVideo.png')
        default:
          return require('@/assets/img/collFile.png')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.leftHeighe {
  height: calc(100vh - 124px);
}

.leftTable {
  padding: 15px;
  background: #FFFFFF;
}

.searchForm {
  padding-bottom: 15px !important;

  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 31%;
    margin-bottom: 0 !important;
  }

  .datTime.el-form-item--small.el-form-item {
    display: inline-block;
    width: 35%;
  }
}

.itemImg {
  height: 50px;
}
</style>