<template>
  <el-dialog title="资源详情" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="资源信息" name="first"></el-tab-pane>
      <el-tab-pane label="资源日志" name="second"></el-tab-pane>
      <el-tab-pane label="资源文件" name="third"></el-tab-pane>
    </el-tabs>
    <div style="width: 100%;overflow-x: hidden">
      <transition name="slide-right">
        <div v-show="activeName == 'first'">
          <el-form size="small" :model="detailsForm" :disabled="basicEdit" :rules="rules" ref="detailsForm"
                   label-width="120px">
            <el-form-item label="缩略图：" prop="url" style="padding-top: 15px">
              <div class="flex_l_t">
                <div
                    v-for="(item,index) in upDateList" :key="index"
                    style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
                >
                  <div class="el-icon-error" v-show="!basicEdit" @click="deleteFile(index)"
                       style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                  <el-image
                      style="width: 100%; height: 110px;"
                      :src="item.netUrl"
                      :fit="'cover'"
                      :preview-src-list="[item.netUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <el-image
                          style="width: 100%; height: 110px;"
                          :src="require('@/assets/img/default.png')"
                          :fit="'cover'">
                      </el-image>
                    </div>
                  </el-image>
                  <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                    <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{
                        item.fileName
                      }}
                    </div>
                  </el-tooltip>
                </div>
                <upload v-show="upDateList.length == 0" :uploadAskObj="uploadRequire2" @getDataList="getImgData">
                  <template v-slot:updateStyle>
                    <div style="width: 100px;
                          text-align: center;
                          height: 100px;
                          line-height: 100px;
                          border: 1px dashed #d9d9d9;
                          border-radius: 6px;
                          ">
                      <div class="el-icon-plus" style="
                            font-size: 20px;
                              "></div>
                    </div>
                  </template>
                </upload>
              </div>
            </el-form-item>
            <el-form-item label="资源名称：" prop="resourceName">
              <el-input v-model.trim="detailsForm.resourceName"
                        placeholder="请填写资源名称(限50字)"
                        style="width: 300px"
                        maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="成文时间：" prop="writtenDate">
              <el-date-picker
                  v-model="detailsForm.writtenDate"
                  type="date" clearable
                  value-format="yyyy-MM-dd"
                  style="width: 300px"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="公开状态：" prop="openFlag">
              <el-switch
                  v-model="detailsForm.openFlag"
                  active-value="0"
                  inactive-value="1">
              </el-switch>
            </el-form-item>
            <el-form-item label-width="0" prop="resourceDesc">
              <div class="text_right" style="width: 108px">资源描述：</div>
              <el-input
                  type="textarea"
                  style="padding-left: 38px"
                  placeholder="请输入内容（限100字）"
                  maxlength="100"
                  rows="5"
                  v-model="detailsForm.resourceDesc"
              >
              </el-input>
            </el-form-item>
          </el-form>
          <div class="text_center">
            <el-button v-if="pageType != 'general'" v-show="basicEdit" type="primary" size="small" @click="editResources()">编辑</el-button>
            <el-button v-show="!basicEdit" type="primary" size="small" @click="saveResources()" v-no-more-click>保存
            </el-button>
            <el-button v-show="!basicEdit" size="small" @click="cancellation()">取消</el-button>

          </div>
        </div>
      </transition>
      <transition name="slide-right">
        <div v-show="activeName == 'second'">
          <div style="padding-bottom: 15px">
            <el-input v-model.trim="keyWord" @keyup.enter.native="searchResources(1)"
                      placeholder="请输入检索关键词(限50字)"
                      style="width: 250px" size="small"
                      maxlength="50" clearable></el-input>
            <el-button style="margin-left: 15px" size="small" type="primary" @click="searchResources(1)"
                       icon="el-icon-search">查询
            </el-button>
            <el-button size="small" @click="resettingResources()" icon='el-icon-refresh-right'>重置</el-button>
          </div>
          <el-table
              :data="resourcesList"
              size="small"
              v-loading="resourcesLoading"
              height="calc(100vh - 265px)"
              class="table"
              :row-key="'id'"
              :header-cell-style="{background:'#F9DFDF'}"
          >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column prop="resourceName" label="操作时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceName" label="操作类型" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceName" label="操作内容" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceName" label="操作结果" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceName" label="操作人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceName" label="所属部门" show-overflow-tooltip></el-table-column>
            <el-table-column prop="resourceName" label="操作IP" show-overflow-tooltip></el-table-column>
          </el-table>
          <div class="text_center" style="padding-top: 15px">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </transition>
      <transition name="slide-right">
        <div v-show="activeName == 'third'">
          <div style="flex-wrap: wrap" class="flex_b_c">
            <div style="padding-bottom: 15px">
              <el-input v-model.trim="searchFile.resourceName" @keyup.enter.native="getSearchFile(1,1)"
                        placeholder="请输入文件名称(限50字)"
                        style="width: 250px" size="small"
                        maxlength="50" clearable></el-input>
              <el-select style="margin-left: 15px" size="small" v-model="searchFile.type"
                         placeholder="请选择类型" clearable>
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.total"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-button style="margin-left: 15px" size="small" type="primary" @click="getSearchFile(1,1)"
                         icon="el-icon-search">查询
              </el-button>
              <el-button size="small" @click="resettingSearchFile()" icon='el-icon-refresh-right'>重置</el-button>
            </div>
            <div style="padding-bottom: 15px" v-if="pageType != 'general'">
              <el-dropdown class="m_r1 m_l1">
                <el-button size="small" type="primary">上传文件</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="selectFile(0)">三维资源</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(1)">图片资源</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(2)">音频资源</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(3)">视频资源</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(4)">出版物数据</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(5)">文献数据</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(6)">文件资料</el-dropdown-item>
                  <el-dropdown-item @click.native="selectFile(7)">其他资源</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small" type="primary" @click="createFolder()">创建文件夹</el-button>
              <el-button size="small" type="primary" @click="fileMove()">移动</el-button>
              <el-button size="small" type="primary" @click="downloadFile()">下载</el-button>
              <el-button size="small" type="primary" @click="joinResources()">合并加入资源库</el-button>
              <el-button icon="el-icon-delete" type="danger" size="small" @click="fileBulkOperation()">批量删除
              </el-button>
            </div>
          </div>
          <resume-upload ref="resumeUpload" :fetchUrl="fetchUrl" @upFileDate="upFileDate" :allowFiles="allowFiles"
                         :upFileSize="upFileSize" :dir="'resource'"
                         :hideUploadPlugin="true" :allowFilesShow="false"></resume-upload>

          <div class="flex_l_c" style="margin-bottom: 15px">
            <div>当前路径：</div>
            <div class="flex_b_c">
              <i class="swipeZhis el-icon-arrow-left"></i>
              <swiper :options="swiperOptions" class="maindboaxe">
                <swiper-slide v-for="(item,index) in pathAddress" :key="item.id"
                              :class="['itemBreadcrumb',index == pathAddress.length - 1 ? 'Selected' : '']"
                >
                  <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                    <div @click="catalogChanges(item,index)" class="omit" style="max-width: 100px">{{ item.name }}
                      {{ index == pathAddress.length - 1 ? '' : '/' }}
                    </div>
                  </el-tooltip>
                </swiper-slide>
              </swiper>
              <i class="swipeZhis el-icon-arrow-right"></i>
            </div>
          </div>
          <el-table
              :data="dataList"
              size="small"
              v-loading="loading"
              ref="multipleTable"
              height="calc(100vh - 300px)"
              class="table"
              :row-key="'id'"
              highlight-current-row
              @current-change="handleCurrentChange"
              :header-cell-style="{background:'#F9DFDF'}"
              @selection-change="selectionChangeHandle"
          >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="url" label="文件预览" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-image v-if="scope.row.folderFlag == 0" fit="contain" :src="require('@/assets/img/folder.png')"
                          class="itemImg"></el-image>
                <el-image v-else fit="contain" :src="scope.row.url" class="itemImg">
                  <el-image slot="placeholder" fit="cover" class="itemImg"
                            :src="getPlaceholderImg(scope.row.type)"></el-image>
                  <el-image slot="error" fit="cover" class="itemImg"
                            :src="getPlaceholderImg(scope.row.type)"></el-image>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="fileName" label="文件名称" width="230px" show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="!scope.row.edit">{{ scope.row.fileName }}</div>
                <el-input v-model.trim="scope.row.fileName"
                          placeholder="请填写文件名称(限60字)" size="small"
                          @input="scope.row.fileName = scope.row.fileName.replace(/[^\u4e00-\u9fa5a-zA-Z0-9_]/g, '')"
                          v-focus
                          @blur="editFolder(scope.row)"
                          style="width: 200px" v-else
                          maxlength="60" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="fileSizeFormat" label="文件大小" show-overflow-tooltip></el-table-column>
            <el-table-column prop="typeName" label="文件类型" show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button v-if="scope.row.folderFlag == 0 && pageType != 'general'" size="mini" type="text" @click.stop="reName(scope.row)">
                  重命名
                </el-button>
                <el-button v-if="scope.row.folderFlag != 0 && pageType != 'general'" size="mini" type="text" @click="joinResources(scope.row)">
                  加入资源库
                </el-button>
                <el-button v-if="scope.row.folderFlag != 0" size="mini" type="text" @click="deleteData(scope.row)">
                  详情
                </el-button>
                <el-button v-if="pageType != 'general'" size="mini" type="text" @click.stop="fileMove(scope.row)">移动</el-button>
                <el-button size="mini" type="text" @click.stop="downloadFile(scope.row)">下载</el-button>
                <el-button v-if="pageType != 'general'" size="mini" type="text" @click.stop="fileBulkOperation(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="text_center" style="padding-top: 15px">
            <el-pagination
                @size-change="fileSizeChangeHandle"
                @current-change="fileCurrentChangeHandle"
                :current-page="fileCurrent"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="fileSize"
                :total="fileTotal"
                background
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </transition>
    </div>
    <file-move ref="fileMove"></file-move>
    <join-resources ref="join-resources" @refreshList="getSearchFile('',1)"></join-resources>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload";
import ResumeUpload from "@/components/Upload/resumeUpload.vue";
import FileMove from "@/views/modules/dyyg/comprehensive/fileMove.vue";
import JoinResources from "@/views/modules/dyyg/comprehensive/joinResources.vue";

export default {
  name: "detailComprehensive",
  components: {JoinResources, FileMove, Upload, ResumeUpload},
  data() {
    return {
      id: '',
      pageType:'',
      visible: false,
      activeName: 'first',
      basicEdit: true,
      detailsForm: {
        url: '',
        resourceName: '',
        writtenDate: null,
        openFlag: '1',
        resourceDesc: '',
      },
      rules: {
        resourceName: [
          {required: true, message: '请填写资源名称', trigger: 'blur'},
        ],
      },
      upDateList: [],
      // 缩略图上传要求
      uploadRequire2: {
        list: [],
        showFile: false, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['jpg', 'png', 'jpeg'],
        megaNum: 10,
        showTips: true,
        customUploadStyle: true,
      },

      keyWord: '',
      resourcesList: [],
      resourcesLoading: false,
      current: 1,
      size: 10,
      total: 0,

      searchFile: {
        resourceName: '',
        type: '',
      },
      options: [
        {
          value: 0,
          total: '三维',
        },
        {
          value: 1,
          total: '图片',
        },
        {
          value: 2,
          total: '音频',
        },
        {
          value: 3,
          total: '视频',
        },
        {
          value: 4,
          total: '出版物',
        },
        {
          value: 5,
          total: '文献',
        },
        {
          value: 6,
          total: '文件',
        },
        {
          value: 7,
          total: '其它',
        },
      ],
      parentId: '',
      fetchUrl: '',
      allowFiles: [],
      upFileSize: 2048,
      fileType: '',
      regex: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,

      pathAddress: [
        {
          id: '',
          name: '//'
        },
      ],
      // Swiper 配置项
      swiperOptions: {
        // 例如，开启自动轮播
        autoplay: false,
        // 设置轮播速度
        speed: 500,
        // 开启循环模式
        loop: false,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.el-icon-arrow-right', // 指定下一个按钮的DOM选择器
          prevEl: '.el-icon-arrow-left', // 指定上一个按钮的DOM选择器
          // 这里可以添加更多配置项来自定义按钮的样式和行为，但上面两个是最基本的
        },
        // ... 其他配置项
      },

      dataList: [],
      notEnterFolder: false,
      loading: false,
      dataListSelect: [],
      fileCurrent: 1,
      fileSize: 10,
      fileTotal: 0,
    }
  },

  methods: {
    init(id,pageType) {
      this.id = id
      this.pageType = pageType
      this.getResource()
      this.visible = true;
      this.$nextTick(() => {
        this.getSearchFile(1, 1)
      })
    },

    //获取基本信息
    getResource() {
      this.$axios(this.api.dyyg.comprehensiveResourcesGetById + this.id, {}, 'get').then((res) => {
        if (res.status) {
          this.basicEdit = true;
          this.upDateList = []
          Object.keys(this.detailsForm).forEach(key => {
            this.detailsForm[key] = res.data[key]
          })
          this.upDateList.push({
            netUrl: this.detailsForm.url,
            name: this.detailsForm.fileName,
          })
        }
      })
    },

    deleteFile(index) {
      this.$confirm('此操作将删除该缩略图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.upDateList.splice(index, 1)
        this.uploadRequire2.list = []
        this.detailsForm.url = ''
      })
    },

    // 获取上传缩略图数据
    getImgData(data) {
      this.upDateList = [
        {
          netUrl: data.response.data.url,
          fileName: data.response.data.name,
        }
      ]
      this.detailsForm.url = data.response.data.path
    },

    //编辑资源基信息
    editResources() {
      this.basicEdit = false;
    },

    //保存资源基本信息
    saveResources() {
      this.$refs.detailsForm.validate((valid) => {
        if (valid) {
          this.$axios(this.api.dyyg.comprehensiveResourcesUpdateById, {
            id: this.id,
            ...this.detailsForm,
          }, 'post').then((res) => {
            if (res.status) {
              this.$message.success('保存成功')
              this.cancellation()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    //资源基本信息取消编辑
    cancellation() {
      this.getResource()
    },

    handleClick(tab, event) {
      console.log(tab)
    },

    //查询资源日志
    searchResources(type) {
      if (type == 1) {
        this.current = 1
      }
      this.resourcesLoading = true
      // this.$axios(this.api.dyyg.resourceSearchByType, {
      //   keyWord: this.keyWord,
      //   id: this.id,
      // }, 'post').then((res) => {
      //   if (res.status) {
      //     this.resourcesList = res.data.records
      //     this.total = parseInt(res.data.total)
      //   } else {
      //     this.$message.error('查询失败');
      //   }
      //   this.resourcesLoading = false
      // })
    },

    //重置检索条件
    resettingResources() {
      this.keyWord = ''
      this.searchResources(1)
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.searchResources(1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.searchResources(1)
    },

    //获取资源文件列表
    getSearchFile(type, dividePage) {
      if (type == 1) {
        this.fileCurrent = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.dyyg.comprehensiveResourcesFileLinkResourceSearchByType, {
        current: this.fileCurrent,
        size: this.fileSize,
        resourceId: this.id,
        parentId: this.parentId,
        ...this.searchFile,
      }, 'post').then((res) => {
        if (res.status) {
          this.pathAddress = [{id: '', name: '//'},]
          if (res.data.resourcePath.length) {
            this.pathAddress.push(...res.data.resourcePath)
          }
          this.dataList = res.data.fileLinkList.records;
          this.fileTotal = parseInt(res.data.fileLinkList.total);
          this.dataList.forEach(item => {
            this.$set(item, 'edit', false)
            this.$delete(item, 'children')
          })
          if (this.dataList.length == 0 && this.fileCurrent > 1) {
            this.fileCurrent--;
            this.getSearchFile()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    //重置资源文件
    resettingSearchFile() {
      this.searchFile.resourceName = ''
      this.searchFile.type = ''
      this.getSearchFile(1, 1)
    },

    //选择上传文件
    selectFile(fileType) {
      this.fileType = fileType
      this.fetchUrl = ''
      if (this.fileType == '0') {
        this.fetchUrl = 'zyd-common/file/uploadThreeFile'
      }
      this.allowFiles = this.getResourceUploadFileFormat(fileType)
      this.$nextTick(() => {
        this.$refs.resumeUpload.$refs.upFile.click()
      })
    },

    //保存上传文件
    upFileDate(data) {
      let row = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileBrowse', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      let files = []
      if (this.fileType != 0) {
        files = [
          {
            fileFormat: row[0].fileFormat,
            fileName: row[0].fileName,
            fileSize: row[0].fileSize,
            fileSizeFormat: row[0].fileSizeFormat,
            url: row[0].filePath,
            type: this.fileType,
          }
        ]
      } else {
        row[0].fileList.forEach(item => {
          files.push(
              {
                fileFormat: item.fileFormat,
                fileName: item.fileName,
                fileSize: item.fileSize,
                fileSizeFormat: item.fileSizeFormat,
                url: item.filePath,
                type: this.fileType,
              }
          )
        })
      }
      this.$axios(this.api.dyyg.comprehensiveResourcesFileLinkSave, {
        files,
        folderFlag: 1,
        folderName: this.fileType == 0 ? row[0].compressName.split('.')[0] : '',
        parentId: this.parentId,
        resourceId: this.id,
      }, 'post').then((res) => {
        if (res.status) {
          this.getSearchFile(1, 1)
        } else {
          this.$message.error(res.msg)
        }
      })
      this.$refs.resumeUpload.deleteFile(row[0].fileID, '', 1)
    },

    //创建文件夹
    createFolder() {
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value || value.length == 0) {
            return '文件夹名称不能为空'
          }
          if (value.length > 60) {
            return '文件夹名称最长为60个字符'
          }
          if (!this.regex.test(value)) {
            return '文件夹名称不正确'
          }
        },
        inputErrorMessage: '文件夹名称不正确',
        inputPlaceholder:'请输入文件夹名称(限60字)',
      }).then(({value}) => {
        this.$axios(this.api.dyyg.comprehensiveResourcesFileLinkSave, {
          files: [],
          folderFlag: 0,
          folderName: value,
          parentId: this.parentId,
          resourceId: this.id,
        }, 'post').then((res) => {
          if (res.status) {
            this.$message.success('添加成功')
            this.getSearchFile(1, 1)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    //点击目录
    catalogChanges(row, index) {
      this.parentId = row.id
      if (index != 0) {
        this.pathAddress.splice(0, index + 1)
      }
      this.getSearchFile(1, 1)
    },

    //进入文件夹
    handleCurrentChange(row) {
      setTimeout(() => {
        if (!this.notEnterFolder && row.folderFlag == 0) {
          let editLista = this.dataList.filter(item => {
            return item.edit == true
          })
          if (!editLista.length) {
            this.parentId = row.id
            this.getSearchFile(1, 1)
          }
        }
      }, 200)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //重命名文件夹
    reName(row) {
      row.edit = true
      this.notEnterFolder = true
    },

    //保存编辑的文件夹名称
    editFolder(row) {
      this.$axios(this.api.dyyg.comprehensiveResourcesFileLinkUpdateById, {
        id: row.id,
        folderFlag: row.folderFlag,
        fileId: row.fileId,
        folderName: row.fileName,
        parentId: this.parentId,
        resourceId: this.id,
      }, 'put').then((res) => {
        if (res.status) {
          this.$message.success('修改成功')
          row.edit = false
          this.notEnterFolder = false
          this.getSearchFile()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //文件批量删除
    fileBulkOperation(row) {
      let ids
      if (!row) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$confirm(`删除该数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.comprehensiveResourcesFileLinkRemoveByIds, ids, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(`删除成功`)
            this.getSearchFile('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //移动
    fileMove(row) {
      let ids
      if (!row) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$refs.fileMove.init(ids)
    },

    //下载资源
    downloadFile(row){
      let list = row ? [row] : this.dataListSelect
      let ids
      if (list.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = list.map(item => {
        if (item) {
          return item.id
        }
      })
      this.$message.info('文件已开始下载，请勿重复操作')
      // this.$axios(this.api.collection.batchDownload, ids, 'post', 'blob').then((res) => {
      //   let blob = new Blob([res.data], {type: 'application/zip'})
      //   let filename = '事故资料';
      //   let link = document.createElement('a')
      //   link.download = decodeURI(filename)
      //   link.href = window.URL.createObjectURL(blob)
      //   link.click()
      // })
    },

    //合并加入资源库
    joinResources(row) {
      let list = row ? [row] : this.dataListSelect
      let ids
      let differentData = []

      if (list.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = list.map(item => {
        if (item.type != list[0].type) {
          differentData.push(item)
        }
        if (item) {
          return item.id
        }
      })
      if (differentData.length != 0) {
        this.$message.warning('只能合并同类型资源！')
        return
      }
      if (!list[0].type && list[0].type != 0) {
        this.$message.warning('文件夹不能加入资源库！')
        return
      }
      this.$refs['join-resources'].init(ids, list[0].type,list[0].fileName)
    },

    // 每页数
    fileSizeChangeHandle(val) {
      this.fileSize = val;
      this.getSearchFile(1, 1)
    },
    // 当前页
    fileCurrentChangeHandle(val) {
      this.fileCurrent = val;
      this.getSearchFile(1)
    },

    // 获取占位图
    getPlaceholderImg(resourceType) {
      switch (resourceType) {
        case '0':
          return require('@/assets/img/collThree.png')
        case '1':
          return require('@/assets/img/collPhoto.png')
        case '2':
          return require('@/assets/img/collAudio.png')
        case '3':
          return require('@/assets/img/collVideo.png')
        default:
          return require('@/assets/img/collFile.png')
      }
    },

    handleClose() {
      if (this.basicEdit == false) {
        this.$confirm('资源信息还未保存，是否关闭？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.visible = false;
          this.$emit('refreshList')
          this.resetPopUp()
        })
      } else {
        this.visible = false;
        this.resetPopUp()
      }
    },

    resetPopUp() {
      this.activeName = 'first'
      this.$refs.detailsForm.resetFields()
      this.keyWord = ''
      this.current = 1
      this.size = 10
      this.searchFile.resourceName = ''
      this.searchFile.type = ''
      this.fileCurrent = 1
      this.fileSize = 10
    },
  },
}
</script>

<style scoped>
.slide-right-enter-active {
  transition: transform 0.3s;
}

.slide-right-leave-active {
  transition: transform 0s;
}

.slide-right-enter, .slide-right-leave-to /* 2.1.8版本及以上使用v-leave-to代替v-leave-active */
{
  transform: translateX(100%);
}

.maindboaxe {
  .swiper-slide {
    width: auto;
  }

  width: calc(100vw - 200px);
  flex: 1;
}

.swipeZhis {
  font-size: 18px;
  padding: 0 5px;
  cursor: pointer;
}

.itemBreadcrumb {
  cursor: pointer;
}

.Selected {
  font-weight: bold !important;
}

.itemImg {
  height: 50px;
  width: auto;
}
</style>